import { once } from 'ramda';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { createReducer } from './reducers/reducer';
import StoreObserver from './StoreObserver';
import documentTitle from './observers/documentTitle';
import executedCallbacks from './observers/executedCallbacks';
import executingCallbacks from './observers/executingCallbacks';
import isLoading from './observers/isLoading';
import loadingMap from './observers/loadingMap';
import prioritizedCallbacks from './observers/prioritizedCallbacks';
import requestedCallbacks from './observers/requestedCallbacks';
import storedCallbacks from './observers/storedCallbacks';
export default class RendererStore {
    constructor() {
        this.__store = this.initializeStore();
    }
    __store;
    get store() {
        return this.__store;
    }
    storeObserver = new StoreObserver();
    setObservers = once(() => {
        const observe = this.storeObserver.observe;
        observe(documentTitle);
        observe(isLoading);
        observe(loadingMap);
        observe(requestedCallbacks);
        observe(prioritizedCallbacks);
        observe(executingCallbacks);
        observe(executedCallbacks);
        observe(storedCallbacks);
    });
    createAppStore = (reducer, middleware) => {
        this.__store = createStore(reducer, middleware);
        this.storeObserver.setStore(this.__store);
        const ds = (window.dash_stores =
            window.dash_stores || []);
        if (!ds.includes(this.__store)) {
            ds.push(this.__store);
        }
        this.setObservers();
    };
    /**
     * Initialize a Redux store with thunk, plus logging (only in development mode) middleware
     *
     * @param {bool} reset: discard any previous store
     *
     * @returns {Store<GenericStoreEnhancer>}
     *  An initialized redux store with middleware and possible hot reloading of reducers
     */
    initializeStore = (reset) => {
        if (this.__store && !reset) {
            return this.__store;
        }
        const reducer = createReducer();
        // eslint-disable-next-line no-process-env
        if (process.env.NODE_ENV === 'production') {
            this.createAppStore(reducer, applyMiddleware(thunk));
        }
        else {
            // only attach logger to middleware in non-production mode
            const reduxDTEC = window
                .__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
            if (reduxDTEC) {
                this.createAppStore(reducer, reduxDTEC({ actionsDenylist: ['reloadRequest'] })(applyMiddleware(thunk)));
            }
            else {
                this.createAppStore(reducer, applyMiddleware(thunk));
            }
        }
        if (!reset) {
            // TODO - Protect this under a debug mode?
            window.store = this.__store;
        }
        if (module.hot) {
            // Enable hot module replacement for reducers
            module.hot.accept('./reducers/reducer', () => {
                const nextRootReducer = require('./reducers/reducer').createReducer();
                this.__store.replaceReducer(nextRootReducer);
            });
        }
        return this.__store;
    };
}
